<template>
  <div>
    <div class="title">
      <div class="container box-series" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <div class="icon-text-wrapper">
          <!-- <img src="@/assets/icons/back.svg" v-if="localDisplayLang === 'eng'" alt @click="initialRoute" />
          <img src="@/assets/icons/back.svg" class="rotate-img" v-else @click="initialRoute" alt />
          &nbsp;&nbsp;&nbsp; -->
          <h1 v-if="screen && screen.title" class="screen-title">{{ (localDisplayLang == "mr" || localDisplayLang == "mar") ? screen.title.mar : screen.title.eng }}</h1>
        </div>
      </div>
    </div>
    <div v-if="screenContent.length === 0">
      <div v-for="(i) in 7" :key="i">
        <categoriesPlaceholder></categoriesPlaceholder>
      </div>
    </div>
    <div class="grid-view" v-else>
      <noorPlayGrid
        :contents="screenContent"
        :section="screen"
        :playerInstance="playerInstance"
        :message="errorMessage"
        :isScrollable="isScrollable"
        @loadMore="loadMoreContents()"
      ></noorPlayGrid>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: {
    menuDetail: {
      type: Object,
    },
    section: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
    routeObject : {
      type: Object
    }
  },
  data() {
    return {
      loader: true,
      screenContent: [],
      errorMessage: null,
      localDisplayLang: null,
      page: 1,
      isScrollable: false,
      isMpegRequired: false,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    $route() {
      this.screenContent = [];
      this.page = 1;
      this.getContentScreen();
    },
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.screenContent = [];
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'mr' ? 'mar' : this.localDisplayLang;
    //checking for change of language in local storage value
    eventBus.$on("check-for-series-route", (data) => {
      this.localDisplayLang = data;
      this.loader = true;
      this.screenContent = [];
      this.isScrollable = false;
      this.page = 1;
      this.getContentScreen();
    });

    this.getContentScreen();
  },
  mounted() {
    console.log("0000===>", this.screen)
    eventBus.$on("get-seeAll-data", (response) => {
      if (!response.reason) {
        if (this.screenContent && this.screenContent.length > 0) {
          this.screenContent = [...this.screenContent, ...response.data];

        } else {
          this.screenContent = [...response.data];
        }
        console.log('SCREEN CONTENT ======',this.screenContent)
        //Set isScrollable flag to true.
        this.isScrollable = response.totalcount === this.screenContent.length ? false : true;
        this.loader = false;
      } else {
        this.loader = false;
        if (response.errorcode != 6066 && response.errorcode != 1001) this.errorMessage = response.reason;
      }
    });
  },
  methods: {
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    //to get the screens from appconfig object.
    // checkMpegRequired() {
    //   let isMpegRequired = false;
    //   let videoTag = document.createElement("video");
    //   let platformOs = navigator.platform;

    //   if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
    //     if (platformOs.startsWith("Mac")) {
    //       isMpegRequired = true;
    //     }
    //   }

    //   return isMpegRequired;
    // },
    getContentScreen() {
      localStorage.setItem("playback_source", this.$router.currentRoute.params.section);
      
      if(this.routeObject && typeof this.routeObject === "object") {
        this.screen = this.routeObject;
        this.getAllContents(this.isMpegRequired);
      } else {
        if (this.$router.currentRoute.params) {
          if (!this.$router.currentRoute.params.routeObject) {
            let menuId = this.$router.currentRoute.params.section;
  
            let index = this.appConfig.screens.findIndex((element) => {
              return (element.id).toLowerCase() === menuId.toLowerCase();
            });
  
            if (index > -1) {
              this.screen = this.appConfig.screens[index].sections[0];
              //get all detail component contents.
              this.getAllContents(this.isMpegRequired);
            } else {
              let screenData = this.appConfig.screens[0].sections.filter((item) => {
                let itemTitle = item.title.default.replace(/[\s&/'/']+/g, "_")
                itemTitle = itemTitle.toLowerCase();
                if (itemTitle.includes(menuId)) {
                  return item;
                }
              });
              console.log(this.appConfig.screens[0].sections);
              this.screen = screenData[0];
              //get all detail component contents.
              this.getAllContents(this.isMpegRequired);
            }
          } else if (this.$router.currentRoute.params.routeObject) {
            this.screen = this.$router.currentRoute.params.routeObject;
            //get all detail component contents.
            this.getAllContents(this.isMpegRequired);
          }
        } else {
          this.screen = this.section;
          //get all detail component contents.
          this.getAllContents(this.isMpegRequired);
        }
      }

    },
    getEndPoint () {
      const sectionType = this.screen.sectionType;
      const itemType = this.screen.itemType;
      
      if (sectionType == "ITEMLIST" && itemType == "CONTENT") {
        return "/subscriber/v1/content"
      } else if (sectionType == "ITEMLIST" && itemType == "TRAILERS") {
         return "/subscriber/v1/metadata/trailer"
      } else if (sectionType == "ITEMLIST" && itemType == "CASTNCREW") {
         return "/subscriber/v1/metadata/castncrew"
      }  else {
         return "/subscriber/v1/content"
      }
    },

    getAllContents(isMpegRequired) {
      if (!this.screen) {
        this.screen = localStorage.getItem("screen-list-collection") ? JSON.parse(localStorage.getItem("screen-list-collection")) : {};
      }
      let payload = {
        endpoint: this.screen.endpoint || this.getEndPoint(),
        params: this.screen.parameters || this.screen.sectionData || {}
      };

      if (this.screen.sectionType == "ITEMLIST") {
          // let strItem = ''
           let item = this.screen.sectionData.map((data) => {
            // strItem += data.id + ","
            return data.id;
           })
          //  strItem = strItem.substring(0, strItem.length - 1);
           if (this.screen.itemType == "CONTENT") {
            payload.params = {
              contentlist : encodeURI(JSON.stringify((item))),
              orderby: {"objectid": item}
            }
           } else {
            payload.params = {
              contentlist : JSON.stringify(item)
            }
           }
         
        }

      console.log("THE API CALL PAYLOAD --- > params ->> ", payload)
      payload.params.displaylanguage = this.localDisplayLang;
      payload.params.page = this.page;

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        payload.params.genre = "Kids";
      }
      setTimeout(() => {
        eventBus.$emit("grid-see-all-data", payload);
      }, 500)
      
    },
    initialRoute() {
      this.$router.push({ name: "Home" });
    },
    loadMoreContents() {
      this.page = this.page + 1;
      this.getAllContents(this.isMpegRequired);
    },
  },
  components: {
    categoriesPlaceholder: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
    noorPlayGrid: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
  },
  beforeDestroy() {
    this.screenContent = [];
    eventBus.$off("check-for-series-route");
    eventBus.$off("get-seeAll-data");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./screen.scss"
</style>
